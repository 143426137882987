body,
html {
    margin: 0;
    padding: 0;
    overflow: hidden;

    /* background-image: url(assets/tile.png); */

    /* background-repeat: repeat; */

    /* background-size: 30px 30px; */
    background-color: #fbfbfb;
    height: 100%;
}

#names {
    display: inline-block;
    vertical-align: top;
}

#title {
    font-weight: 500;
    font-size: 16px;
    color: #393c44;
    margin-bottom: 0;
}

#subtitle {
    color: #808292;
    font-size: 14px;
    margin-top: 5px;
}

/* #leftside {
    display: inline-block;
    vertical-align: middle;
    margin-left: 20px;
} */

#centerswitch {
    position: absolute;
    width: 222px;
    left: 50%;
    margin-left: -111px;
    top: 15px;
}

#leftswitch {
    border: 1px solid #e8e8ef;
    background-color: #fbfbfb;
    width: 111px;
    height: 39px;
    line-height: 39px;
    border-radius: 5px 0 0 5px;
    color: #393c44;
    display: inline-block;
    font-size: 14px;
    text-align: center;
}

#rightswitch {
    color: #808292;
    border-radius: 0 5px 5px 0;
    border: 1px solid #e8e8ef;
    height: 39px;
    width: 102px;
    display: inline-block;
    font-size: 14px;
    line-height: 39px;
    text-align: center;
    margin-left: -5px;
}

/* #discard {
    font-weight: 500;
    font-size: 14px;
    color: #a6a6b3;
    width: 95px;
    height: 38px;
    border: 1px solid #e8e8ef;
    border-radius: 5px;
    text-align: center;
    line-height: 38px;
    display: inline-block;
    vertical-align: top;
    transition: all 0.2s cubic-bezier(0.05, 0.03, 0.35, 1);
}

#discard:hover {
    cursor: pointer;
    opacity: 0.7;
}

#publish {
    font-weight: 500;
    font-size: 14px;
    color: #fff;
    background-color: #217ce8;
    border-radius: 5px;
    width: 143px;
    height: 38px;
    margin-left: 10px;
    display: inline-block;
    vertical-align: top;
    text-align: center;
    line-height: 38px;
    margin-right: 20px;
    transition: all 0.2s cubic-bezier(0.05, 0.03, 0.35, 1);
}

#publish:hover {
    cursor: pointer;
    opacity: 0.7;
} */

/* #buttonsright {
    float: right;
    margin-top: 15px;
} */

/* #search input {
    width: 318px;
    height: 40px;
    background-color: #fff;
    border: 1px solid #e8e8ef;
    box-sizing: border-box;
    box-shadow: 0 2px 8px rgb(34 34 87 / 5%);
    border-radius: 5px;
    text-indent: 35px;
    font-size: 16px;
} */

input::input-placeholder {
    /* Edge */
    color: #c9c9d5;
}

input:input-placeholder {
    /* Internet Explorer 10-11 */
    color: #c9c9d5;
}

::placeholder {
    color: #c9c9d5;
}

/* #search img {
    position: absolute;
    margin-top: 10px;
    width: 18px;
    margin-left: 12px;
} */

#header {
    font-size: 20px;
    font-weight: bold;
    color: #393c44;
}

/* .blockelem:first-child {
    margin-top: 20px;
}

.blockelem {
    padding-top: 10px;
    width: 318px;
    border: 1px solid transparent;
    transition-property: box-shadow, height;
    transition-duration: 0.2s;
    transition-timing-function: cubic-bezier(0.05, 0.03, 0.35, 1);
    border-radius: 5px;
    box-shadow: 0 0 30px rgb(22 33 74 / 0%);
    box-sizing: border-box;
}

.blockelem:hover {
    box-shadow: 0 4px 30px rgb(22 33 74 / 8%);
    border-radius: 5px;
    background-color: #fff;
    cursor: pointer;
} */

/* .grabme,
.blockico {
    display: inline-block;
}

.grabme {
    margin-top: 10px;
    margin-left: 10px;
    margin-bottom: -14px;
    width: 15px;
} */

/* #blocklist {
    height: calc(100% - 220px);
    overflow: auto;
} */

#proplist {
    height: calc(100% - 305px);
    overflow: auto;
    margin-top: -30px;
    padding-top: 30px;
}

/* .blockin {
    display: inline-block;
    vertical-align: top;
    margin-left: 12px;
}

.blockico {
    width: 36px;
    height: 36px;
    background-color: #f1f4fc;
    border-radius: 5px;
    text-align: center;
    white-space: nowrap;
}

.blockico span {
    height: 100%;
    width: 0;
    display: inline-block;
    vertical-align: middle;
}

.blockico img {
    vertical-align: middle;
    margin-left: auto;
    margin-right: auto;
    display: inline-block;
}

.blocktext {
    display: inline-block;
    width: 220px;
    vertical-align: top;
    margin-left: 12px;
}

.blocktitle {
    margin: 0 !important;
    padding: 0 !important;

    font-weight: 500;
    font-size: 16px;
    color: #393c44;
}

.blockdesc {
    margin-top: 5px;

    color: #808292;
    font-size: 14px;
    line-height: 21px;
}

.blockdisabled {
    background-color: #f0f2f9;
    opacity: 0.5;
} */

#dataprop {
    /* font-family: Roboto, Helvetica, Arial, Verdana, Tahoma, sans-serif; */
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    color: #393c44;
    width: calc(88% / 3);
    height: 48px;
    line-height: 48px;
    display: block;
    float: left;
    margin-left: 20px;
}

#dataprop::after {
    display: block;
    content: "";
    width: 100%;
    height: 4px;
    background-color: #217ce8;
    margin-top: -4px;
}

#alertprop {
    display: block;

    /* font-family: Roboto, Helvetica, Arial, Verdana, Tahoma, sans-serif; */
    font-weight: 500;
    color: #808292;
    font-size: 14px;
    height: 48px;
    line-height: 48px;
    width: calc(88% / 3);
    text-align: center;
    float: left;
}

#logsprop {
    width: calc(88% / 3);
    display: inline-block;

    /* font-family: Roboto, Helvetica, Arial, Verdana, Tahoma, sans-serif; */
    font-weight: 500;
    color: #808292;
    font-size: 14px;
    height: 48px;
    line-height: 48px;
    text-align: center;
}

.inputlabel {
    /* font-family: Roboto, Helvetica, Arial, Verdana, Tahoma, sans-serif; */
    font-size: 14px;
    color: #253134;
}

.dropme {
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid #e8e8ef;
    box-shadow: 0 2px 8px rgb(34 34 87 / 5%);

    /* font-family: Roboto, Helvetica, Arial, Verdana, Tahoma, sans-serif; */
    font-size: 14px;
    color: #253134;
    text-indent: 20px;
    height: 40px;
    line-height: 40px;
    width: 287px;
    margin-bottom: 25px;
}

.dropme img {
    margin-top: 17px;
    float: right;
    margin-right: 15px;
}

.checkus {
    margin-bottom: 10px;
}

.checkus img {
    display: inline-block;
    vertical-align: middle;
}

.checkus p {
    display: inline-block;

    /* font-family: Roboto, Helvetica, Arial, Verdana, Tahoma, sans-serif; */
    font-size: 14px;
    vertical-align: middle;
    margin-left: 10px;
}

#divisionthing {
    height: 1px;
    width: 100%;
    background-color: #e8e8ef;
    position: absolute;
    right: 0;
    bottom: 80;
}

#removeblock {
    border-radius: 5px;
    position: absolute;
    bottom: 20px;

    /* font-family: Roboto, Helvetica, Arial, Verdana, Tahoma, sans-serif; */
    font-size: 14px;
    text-align: center;
    width: 287px;
    height: 38px;
    line-height: 38px;
    color: #253134;
    border: 1px solid #e8e8ef;
    transition: all 0.3s cubic-bezier(0.05, 0.03, 0.35, 1);
}

#removeblock:hover {
    cursor: pointer;
    opacity: 0.5;
}

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */ /* Safari */ /* Konqueror HTML */ /* Old versions of Firefox */ /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}

.blockyname {
    /* font-family: Roboto, Helvetica, Arial, Verdana, Tahoma, sans-serif; */
    font-weight: 500;
    color: #253134;
    display: inline-block;
    vertical-align: middle;
    margin-left: 8px;
    font-size: 16px;
}

.blockyleft img {
    display: inline-block;
    vertical-align: middle;
}

.blockyright {
    display: block;
    float: right;

    /* vertical-align: middle; */
    margin-right: 20px;
    margin-top: 10px;
    width: 28px;
    height: 28px;
    border-radius: 5px;
    text-align: center;
    background-color: #fff;
    transition: all 0.3s cubic-bezier(0.05, 0.03, 0.35, 1);
    z-index: 10;
}

.blockyright:hover {
    background-color: #f1f4fc;
    cursor: pointer;
}

.blockyright img {
    margin-top: 12px;
}

.blockyleft {
    display: inline-block;
    margin-left: 20px;
}

.blockydiv {
    width: 100%;
    height: 1px;
    background-color: #e9e9ef;
}

.blockyinfo {
    /* font-family: Roboto, Helvetica, Arial, Verdana, Tahoma, sans-serif; */
    font-size: 14px;
    color: #808292;
    margin-top: 15px;
    text-indent: 20px;
    margin-bottom: 20px;
}

.blockyinfo span {
    color: #253134;
    font-weight: 500;
    display: inline-block;
    border-bottom: 1px solid #d3dcea;
    line-height: 20px;
    text-indent: 0;
}

.block {
    background-color: #fff;
    margin-top: 0 !important;
    box-shadow: 0 4px 30px rgb(22 33 74 / 5%);
}

.selectedblock {
    border: 2px solid #217ce8;
    box-shadow: 0 4px 30px rgb(22 33 74 / 8%);
}

@media only screen and (width <= 832px) {
    #centerswitch {
        display: none;
    }
}
@media only screen and (width <= 560px) {
    #names {
        display: none;
    }
}
